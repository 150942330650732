body { 
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

canvas {
    width: 100vw;
    height: 100vh;
    user-select: none;
}
